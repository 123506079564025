<template>
  <!--
    The view for the InstallationWebhookList-component.
    Displays all installation webhooks.
  -->
  <Portlet
    title="Webhooks"
    class="webhooks"
    icon="swatchbook"
  >
    <InstallationWebhookList 
      ref="list"
      :installation-id="installationId"
    />
  </Portlet>
</template>

<script>
export default {
  name: "InstallationDetailInstallationWebhooks",
  components: {
    InstallationWebhookList: () => import('@/components/Installation/InstallationWebhookList.vue'),
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  }
}
</script>